import React from "react";
import { useLoader } from "../../LoaderContext";

const CircularLoader = () => {
    const { isLoading } = useLoader();  

    if (!isLoading) return null;

    return (
        <div className="loader-overlay">
            <div className="circular-loader"></div>
        </div>
    );
};

export default CircularLoader;
