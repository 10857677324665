import React, { Component, useState } from "react";
import "./ProfilePage.css";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import url from "../../Utility/URL/url";
import defaultProfilePic from "../../Utility/images/profilepic.jpg";
import Loader from "../../Utility/loader/Loader";
import { getPriceUtility } from "../../Utility/Price/getPriceUtility";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FeedbackBanner from "../../Utility/images/feedback2.gif";
import videoIcon from "../../Utility/images/icons/videoCall.svg";
import chatIcon from "../../Utility/images/icons/chat.svg";
import customLinkIcon from "../../Utility/images/icons/customLink.svg";
import facebookIcon from "../../Utility/images/icons/facebook.svg";
import instagramIcon from "../../Utility/images/icons/instagram.svg";
import linkedinIcon from "../../Utility/images/icons/linkedin.svg";
import twitterIcon from "../../Utility/images/icons/twitterIcon.svg";
import youtubeIcon from "../../Utility/images/icons/youtube.svg";


const ProfilePage = () => {
  //First getting the username from the url by using the useParam;
  const { username } = useParams();
  const [loading, setLoading] = useState(true);

  //advisorInformation after apicall
  const [advisorInformation, setAdvisorInformation] = useState(null);

  //whenever inside the array of useEffect Value changes then useEffect runs.
  //Empty array means onMount like componentOnMount function in class.
  useEffect(() => {
    getData();
  }, []);

  const [selectedType, setSelectedType] = useState("All");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2.2,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.4,
      partialVisibilityGutter: 50,
    },
  };

  function returnProfilePicString() {
    //if the image comes with the profileData then return the image.
    //also check wether it's a image name or the url the backend can contain anything .
    //if its a url then show the return the url else show the image name with.png

    //if it's not available then return the default pic.

    if (advisorInformation) {
      if (advisorInformation.profileData.profilePic) {
        const url = advisorInformation.profileData.profilePic;

        if (isValidUrl(url)) {
          return url;
        } else {
          try {
            const image = require(`../../Utility/images/${url}`);
            return image;
          } catch (error) {
            return defaultProfilePic;
          }
        }
      } else {
        return defaultProfilePic;
      }
    }
  }



  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  async function getData() {
    try {
      const response = await axios.get(
        url.GET_USER_PROFILE_INFORMATION + username
      );
      setAdvisorInformation(response.data);
      setLoading(false);
    } catch (error) {
      toast.error("User not Found..");
    }
  }

  const getServicesOffered = () => {
    const { servicesOffered } = advisorInformation ? advisorInformation : [];

    const activeServices = servicesOffered
      ? servicesOffered.filter((service) => !service.hideInProfileFlag)
      : [];

    // Get unique types dynamically from the data and add "All"
    const types = ["All", ...new Set(activeServices.map((item) => item.type))];

    const filteredData =
      selectedType === "All" ? activeServices : activeServices.filter((item) => item.type === selectedType);

    return (
      <div className="reach-out-to-me">
        <div className="container">
          {activeServices.length > 0 ? (
            <div className="container">
              <div className="reaching-heading">
                <h1 >Reach out to me</h1>
              </div>
              <div className="reaching-methods">
                <div className="row">
                  <div className="categoryContainer nav-tabs">
                    {types.map((category) => (
                      <button
                        key={category}
                        onClick={() => setSelectedType(category)}
                        className={`serviceCategoryButton ${selectedType === category ? category === 'All' ? "active-blue" : category === 'ONE_ON_ONE_CALL' ? 'active-red' : 'active-green' : ''}`}
                      >
                        {category === 'ONE_ON_ONE_CALL' && '1:1 Video Call'}
                        {category !== 'ONE_ON_ONE_CALL' && category.replace(/_/g, " ")
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </button>
                    ))}
                  </div>
                  {filteredData.map((service, index) => {
                    return !service.hideInProfileFlag ? (
                      <div className="col-lg-6 my-lg-3 my-2" key={index}>
                        <div className="card service-card bsnone">
                          <div className="card-body" style={{ width: "100%" }}>
                            <div className="card-title">
                              <div className="row">
                                <div className="col-lg-2 col-2">
                                  <div className="video-icon">
                                    {service?.type === 'TEXT_QUERY' && <img src={chatIcon} className="" />}
                                    {service?.type === 'ONE_ON_ONE_CALL' && <img src={videoIcon} className="" />}
                                  </div>
                                </div>
                                <div className="col-lg-10 col-10">
                                  <div className="service-title fs-4 text-left">
                                    {service.title}
                                  </div>
                                  {/* <p className="card-text pt-2 text-left">
                                    {service.type === "ONE_ON_ONE_CALL"
                                      ? "1:1 Video Meet"
                                      : service.type === "TEXT_QUERY" ? "Text Query" : service.type}
                                  </p> */}
                                  <p className="card-text pt-2 text-left">
                                    {service?.shortDescription}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="card-footer-section">
                              <div className="donation-container text-center d-lg-flex justify-content-between">
                                <div className="mt-3 d-flex justify-content-between align-items-center ">
                                  <div className="bg-green-time text-center p-2 ">
                                    <i className="fa-solid fa-stopwatch text-black pr-2"></i>
                                    {service.type !== "TEXT_QUERY" &&
                                      <>
                                        {service.callDuration} min
                                      </>
                                    }
                                    {service.type === "TEXT_QUERY" &&
                                      <>
                                        Answer in 2 days
                                      </>
                                    }
                                  </div>
                                  <div className="bg-yellow-ruppes text-center p-2 ml-2">
                                    <i className="fa-solid fa-money-bill text-black pr-2"></i>
                                    {getPriceUtility(
                                      advisorInformation.profileData.currency,
                                      service.price
                                    )}
                                  </div>
                                </div>
                                <div className="btn btn-primary py-2 px-3 mt-3">
                                  <Link
                                    to={
                                      service.type === "TEXT_QUERY"
                                        ? `/${service.username}/${service.platformServiceId}/priority/dm`
                                        : `/${service.username}/${service.platformServiceId}`
                                    }
                                    state={{
                                      from: "profilePage",
                                      advisorInfo: advisorInformation,
                                      serviceInformation: service,
                                    }}
                                  >
                                    Book Now
                                  </Link>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>{" "}
            </div>
          ) : (
            <div className="container">
              <div className="h1">
                Hey! Just created my profile on AdvisingBuddy.com stay tuned for
                more update
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const StarRating = ({ totalStars = 5, rating = 0 }) => {
    const renderStars = () => {
      return Array.from({ length: totalStars }, (_, index) => {
        const starIndex = index + 1;
        const isFilled = starIndex <= rating;

        return (
          <svg
            key={index}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={isFilled ? "#FFD700" : "#D3D3D3"}
            style={{ width: "15px", height: '15px' }}
          >
            <path
              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
            />
          </svg>
        );
      });
    };

    return <div className="d-flex">{renderStars()}</div>;
  };


  getServicesOffered();
  function getPublicProfilePage() {
    // console.log(advisorInformation.);

    // window.onscroll = function () { scrollFunction() };

    // function scrollFunction() {
    //   if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    //     document.getElementById("public-profile-image").style.width = "200px";
    //     document.getElementById("public-profile-image").style.height = "200px";
    //     document.getElementById("public-profile-section").style.height = "90px";
    //     document.getElementById("public-profile-section").style.position = "sticky";
    //     document.getElementById("public-profile-section").style.top = "79px";
    //     document.getElementById("profile-name").style.display = "block";
    //   } else {
    //     document.getElementById("public-profile-image").style.width = "300px";
    //     document.getElementById("public-profile-image").style.height = "300px";
    //     document.getElementById("public-profile-section").style.height = "150px";
    //     document.getElementById("public-profile-section").style.position = "relative";
    //     document.getElementById("public-profile-section").style.top = "0";
    //     document.getElementById("profile-name").style.display = "none";


    //   }
    // }

    return (
      <div className="public-profilePage">
        <div className="header-block">
          <div className="header-wrapper flex justify-content-between border-bottom-1">
            <a variant="base" className="header-logo" href="/">
              <div className="logo-wrapper" data-testid="logo" width="auto">
                Advising Buddy
              </div>
            </a>
          </div>
        </div>
        <main>
          <div className="public-profile-main">
            <div className="flex items-center justify-center min-h-screen rounded-xl shadow-lg bg-profile-header mb-3 public-profile-section" id="public-profile-section">
              <div className="public-profile-image">
                <img
                  src={returnProfilePicString()}
                  className="rounded-circle p-lg-4 p-3"
                  alt="loading..."
                  id="public-profile-image"
                />
                {/* <div className="col-lg-12 d-flex">
                  <div className="col-lg-1 d-none d-lg-block"></div>
                  <div className="col-lg-11 col-9 pl-2 pl-lg-5 profile-user-name text-white pt-4" id="profile-name">
                    {advisorInformation
                      ? " " + advisorInformation.profileData.fullName
                      : ""}
                  </div>

                </div> */}
              </div>
            </div>
            <div className="col-lg-12 col-12 d-flex flex-column-reverse flex-lg-row profile-details-block">
              <div className="col-lg-3 d-none d-lg-block"></div>
              <div className="col-lg-7 col-12 pl-2 pl-lg-4">
                <p className="profile-user-name text-gray-800 pt-lg-0 pt-5">
                  {advisorInformation
                    ? " " + advisorInformation.profileData.fullName
                    : ""}</p>
                <ReadMoreLess text={advisorInformation.profileData.description} />
              </div>
              <div className="col-lg-2 col-0 position-relative">
                <div className="user-social-links mt-1">
                  <div className="d-flex flex-row flex-lg-nowrap w-75 flex-wrap mr-3 mr-lg-0 align-items-center justify-content-end social-icons-container">
                    {advisorInformation?.profileData?.linkedinLink && (
                      <a href={advisorInformation.profileData.linkedinLink} target="_blank" rel="noreferrer" className="social-icon-wrapper">
                        <img src={linkedinIcon} alt="LinkedIn" className="user-links-linkedin-icons" />
                      </a>
                    )}

                    {advisorInformation?.profileData?.twitterLink && (
                      <a href={advisorInformation.profileData.twitterLink} target="_blank" rel="noreferrer" className="social-icon-wrapper">
                        <img src={twitterIcon} alt="Twitter" className="user-links-twitter-icons" />
                      </a>
                    )}

                    {advisorInformation?.profileData?.youtubeLink && (
                      <a href={advisorInformation.profileData.youtubeLink} target="_blank" rel="noreferrer" className="social-icon-wrapper">
                        <img src={youtubeIcon} alt="YouTube" className="user-links-youtube-icons" />
                      </a>
                    )}

                    {advisorInformation?.profileData?.customLink && (
                      <a href={advisorInformation.profileData.customLink} target="_blank" rel="noreferrer" className="social-icon-wrapper">
                        <img src={customLinkIcon} alt="Custom Link" className="user-links-customlink-icons" />
                      </a>
                    )}

                    {advisorInformation?.profileData?.instagramLink && (
                      <a href={advisorInformation.profileData.instagramLink} target="_blank" rel="noreferrer" className="social-icon-wrapper">
                        <img src={instagramIcon} alt="Instagram" className="user-links-instagram-icons" />
                      </a>
                    )}
                    {advisorInformation?.profileData?.facebookLink && (
                      <a href={advisorInformation.profileData.facebookLink} target="_blank" rel="noreferrer" className="social-icon-wrapper">
                        <img src={facebookIcon} alt="Instagram" className="user-links-facebook-icons" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* services offered by advisor */}
            <section>{getServicesOffered()}</section>

            {/* feedback section  */}
            <section>
              {advisorInformation ? (
                advisorInformation.userReviews.length === 0 ? (
                  ""
                ) : (
                  <div className="feedback-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-3 d-flex flex-column align-items-center">
                          <div className="feedback-heading-image">
                            <div className="feedback-heading">
                              <h1>Feedback</h1>
                            </div>
                          </div>
                          <div className="feedback-image ">
                            <img
                              src={FeedbackBanner}
                              alt="not-found"
                              className="actual-feedback-image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="feedback-container">
                            <div className="row">
                              {advisorInformation ? (
                                <React.Fragment>
                                  <Carousel
                                    responsive={responsive}
                                    infinite={true}
                                    partialVisible={true}
                                    autoPlay={true}
                                    autoPlaySpeed={2000}
                                    keyBoardControl={true}
                                    customTransition="all 0.5s ease"
                                    containerClass="carousel-container"
                                    itemClass="carousel-item-spacing"
                                    arrows={false}
                                  >
                                    {advisorInformation.userReviews.map((review, index) => (
                                      <div key={index} className="feedback-card-container">
                                        <div className="card feedback-card shadow-none border rounded">
                                          <div
                                            className="card-body"
                                            style={{ width: "100%", textAlign: "center" }}
                                          >
                                            <div className="card-title">
                                              <div className="feedback-title feedback-name-of-person">
                                                <div className="d-flex">
                                                  <div className="col-lg-2 col-2 px-0">
                                                    <div className="feedback-profile-img">
                                                      <img
                                                        src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png"
                                                        alt="User"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-10 text-left">
                                                    <h5>{review.name}</h5>
                                                    <div className="items-center justify-center bg-gray-100">
                                                      <StarRating
                                                        totalStars={5}
                                                        rating={Math.round(review.rating)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <p className="card-text mx-5 px-2 pt-2 fs-5 text-left">
                                              {review.description}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </Carousel>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
            </section>
          </div>
        </main>
      </div>
    );
  }

  const ReadMoreLess = ({ text, maxLength = 300 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => setIsExpanded(!isExpanded);

    const isTextLong = text.length > maxLength;

    return (
      <div className="user-experience fs-5 d-flex flex-lg-row flex-column align-items-end">
        <span
          dangerouslySetInnerHTML={{
            __html: `${text
              ? isExpanded || !isTextLong
                ? text
                : `${text.slice(0, maxLength)}...`
              : ""
              }`,
          }}
        ></span>
        {isTextLong && (
          <>
            {!isExpanded && <span></span>}
            <span
              onClick={toggleReadMore}
              className="see-more-less-button"
            >
              {isExpanded ? " see less" : " see more"}
            </span>
          </>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      {/* {advisorInformation ? getPublicProfilePage() : <loader />} */}
      {/* {loading ? <loader /> : getPublicProfilePage()} */}
      {loading && (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Loader />
        </div>
      )}
      {advisorInformation && <div>{getPublicProfilePage()}</div>}
    </React.Fragment>
  );
};

export default ProfilePage;
