import React from "react";
import "./loader.css";

function Loader() {
  return <div className="loader">
    <p className="heading">Loading...</p>
    <div className="loading">
      <div className="load"></div>
      <div className="load"></div>
      <div className="load"></div>
      <div className="load"></div>
    </div>
  </div>;
}

export default Loader;
