import { useEffect, useState } from "react";

const Modal = ({ isOpen, onClose = () => { }, children, closeModalOnEscapePress = false }) => {
  // const [modalIsOpen, setModalIsOpen] = useState(isOpen ? true : false);
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
  //   }
  //   if (modalIsOpen) {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [isOpen, modalIsOpen]);

  useEffect(() => {
    if (closeModalOnEscapePress && isOpen) {
      const closeOnEscapeKey = (e) =>
        e.key === "Escape" ? onClose() : null;
      document.body.addEventListener("keydown", closeOnEscapeKey);
      return () => {
        document.body.removeEventListener("keydown", closeOnEscapeKey);
        return;
      };
    }
  }, [isOpen, closeModalOnEscapePress]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content rounded" onClick={(e) => e.stopPropagation()}>
        <div className="bg-light rounded p-lg-4 p-3">
          <div className="cross-btn d-flex justify-content-end" onClick={onClose}><i className="fa-solid fa-xmark"></i></div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
