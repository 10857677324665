import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./ServiceInformation.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import url from "../../Utility/URL/url.js";
import Select from "react-select";
import DOMPurify from "dompurify";
import { getPriceUtility } from "../../Utility/Price/getPriceUtility";
import defaultProfilePic from "../../Utility/images/profilepic.jpg";
import { useParams, useLocation } from "react-router-dom";
import Modal from "../shared/Modal/index.js";
import ServicePayment from "./ServicePayment.jsx";
import BookingConfirmation from "./BookingConfirmation.jsx";
import BookingFailed from "./BookingFailed.jsx";

const ServiceInformation = ({ advisorInfo, serviceInformation }) => {
  const [error, setError] = useState({ selectedTime: "" });
  const options = { hour12: true, hour: "numeric", minute: "numeric" };
  const timezones = require("../../Utility/timezone.js");
  const userMachineTime = new Date()
    .toLocaleTimeString("en-US", options)
    .toLowerCase();

  const username = advisorInfo.profileData.username;
  const defaultOption = {
    label: "(UTC +05:30) India (IST)",
    value: "Asia/Kolkata",
  };
  const [skeleton, setSkeleton] = useState(true);
  const [serviceFeedback, setServiceFeedback] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [isWeekDayDisabled, setIsWeekDayDisabled] = useState(false);

  const navigate = useNavigate();
  const [selectDate, setSelectDate] = useState(new Date());

  // const location = useLocation();
  // const { advisorInfo, serviceInformation } = location.state;

  let [timeSlot, setTimeSlot] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");

  let formattedDate = moment(selectDate.$d).format("YYYY-MM-DD");
  let weekDayName = moment(selectDate.$d).format("dddd");

  function getWeekFromAvailability(json) {
    const { availability } = json;
    //now availability is an array of json's need to find the right week.

    const startEnd = availability.find((week) => {
      if (week[weekDayName.toUpperCase()]) {
        return true;
      }
    });

    return startEnd ? startEnd[weekDayName.toUpperCase()] : "";
  }

  const advisiorStartingTime = getWeekFromAvailability(advisorInfo).startTime;

  timezones.sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });

  const isWeekend = (date) => {
    const day = date.day();
    // 0-> sunday
    //6 -> saturday.

    const options = { weekday: "long" }; // set the 'weekday' option to get the full weekday name
    const weekName = date.$d.toLocaleDateString("en-US", options); // get the week name in English (you can change 'en-US' to your desired locale)
    // console.log(weekName); // output: "Monday" (or the current weekday name depending on the date)
    const { availability } = advisorInfo;

    const week = availability.find((week) => {
      const [firstKey] = Object.keys(week);
      if (firstKey.toLowerCase() === weekName.toLowerCase()) {
        return true;
      }
    });
    if (week[weekName.toLowerCase()]) {
      return false;
    } else {
      return true;
    }
  };

  function filterFeedBack() {
    const { userReviews } = advisorInfo;

    return userReviews.filter(
      (review) =>
        review.platformServiceId === serviceInformation.platformServiceId
    );
  }
  //when the user select another date then some api should call.

  // useEffect(() => {
  //   //filter the feedbacks for the selected serviceId.
  //   setServiceFeedback(filterFeedBack());
  //   let finalStartTime = roundTime(userMachineTime);

  //   // startTime is also depend on the availability time
  //   let jsonObject = {
  //     advisorTimezone: advisorInfo.profileData.timezone,
  //     date: moment(new Date()).format("YYYY-MM-DD"),
  //     userSelectedTimezone: selectedTimeZone
  //       ? selectedTimeZone.value
  //       : "Asia/Kolkata",
  //     startTime: advisiorStartingTime,
  //     endTime: getWeekFromAvailability(advisorInfo).endTime,
  //     timeInterval: serviceInformation.callDuration,
  //     localTime: finalStartTime,
  //     userSystemCurrentDate: moment(new Date()).format("YYYY-MM-DD"),
  //     username: username,
  //   };

  //   axios
  //     .post(url.POST_AVAILABLE_TIME_SLOT, jsonObject)
  //     .then((res) => {
  //       setTimeSlot(res.data.availableTimeSlots);
  //       setSkeleton(false);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   //when date changes the selected time will get back to be unselected.
  //   setSelectedTime("");
  //   setSkeleton(true);
  //   //if date is not today's then the start Time need to be send as the advisior time.
  //   // startTime is also depend on the availability time
  //   // let finalStartTime = roundTime(userMachineTime);
  //   let finalStartTime = roundTime(userMachineTime);
  //   let jsonObject = {
  //     advisorTimezone: advisorInfo.profileData.timezone,

  //     date: formattedDate,
  //     userSelectedTimezone: selectedTimeZone
  //       ? selectedTimeZone.value
  //       : "Asia/Kolkata",
  //     startTime: advisiorStartingTime,
  //     endTime: getWeekFromAvailability(advisorInfo).endTime,
  //     timeInterval: serviceInformation.callDuration,
  //     localTime: finalStartTime,
  //     userSystemCurrentDate: moment(new Date()).format("YYYY-MM-DD"),
  //     username: username,
  //   };

  //   axios
  //     .post(url.POST_AVAILABLE_TIME_SLOT, jsonObject)
  //     .then((response) => {
  //       setTimeSlot(response.data.availableTimeSlots);
  //       setSkeleton(false);
  //     })
  //     .catch((error) => console.log("Error comes at time slot api ", error));
  // }, [formattedDate]);

  // useEffect(() => {
  //   setSelectedTime("");
  //   let finalStartTime = roundTime(userMachineTime);

  //   setSkeleton(true);
  //   // startTime is also depend on the availability time
  //   let jsonObject = {
  //     advisorTimezone: advisorInfo.profileData.timezone,

  //     date: formattedDate,
  //     userSelectedTimezone: selectedTimeZone
  //       ? selectedTimeZone.value
  //       : "Asia/Kolkata",
  //     startTime: advisiorStartingTime,
  //     endTime: getWeekFromAvailability(advisorInfo).endTime,
  //     timeInterval: serviceInformation.callDuration,
  //     localTime: finalStartTime,
  //     userSystemCurrentDate: moment(new Date()).format("YYYY-MM-DD"),
  //     username: username,
  //   };
  //   axios
  //     .post(url.POST_AVAILABLE_TIME_SLOT, jsonObject)
  //     .then((res) => {
  //       setTimeSlot(res.data.availableTimeSlots);
  //       setSkeleton(false);
  //     })
  //     .catch((err) => console.log(err));
  // }, [selectedTimeZone]);

  const checkDateAvailability = (date) => {
    const options = { weekday: "long" };
    const weekName = moment(date).format("dddd"); // Get full weekday name

    const { availability } = advisorInfo;

    const week = availability.find((week) => {
      const [firstKey] = Object.keys(week);
      return firstKey.toLowerCase() === weekName.toLowerCase();
    });

    return week && week[weekName.toLowerCase()] ? true : false;
  };


  useEffect(() => {
    // Set up initial states for time and skeleton loader
    setSelectedTime("");
    setSkeleton(true);
    setServiceFeedback(filterFeedBack());

    // Get the selected date or use today's date
    const selectedDate = formattedDate || moment(new Date()).format("YYYY-MM-DD");

    // Check if the selected date is available in the advisor's schedule
    const isDateAvailable = checkDateAvailability(selectedDate);

    if (!isDateAvailable) {
      console.log("Selected date is not available. Skipping API call.");
      setSkeleton(false); // Ensure skeleton loader is disabled
      return;
    }

    // Calculate the final start time
    const finalStartTime = roundTime(userMachineTime);

    // Build the payload dynamically
    const jsonObject = {
      advisorTimezone: advisorInfo.profileData.timezone,
      date: selectedDate,
      userSelectedTimezone: selectedTimeZone ? selectedTimeZone.value : "Asia/Kolkata",
      startTime: advisiorStartingTime,
      endTime: getWeekFromAvailability(advisorInfo).endTime,
      timeInterval: serviceInformation.callDuration,
      localTime: finalStartTime,
      userSystemCurrentDate: moment(new Date()).format("YYYY-MM-DD"),
      username: username,
    };

    // API Call
    axios
      .post(url.POST_AVAILABLE_TIME_SLOT, jsonObject)
      .then((res) => {
        setTimeSlot(res.data.availableTimeSlots); // Set available time slots
        setSkeleton(false); // Disable skeleton loader
      })
      .catch((err) => {
        console.log("Error comes at time slot API", err);
        setSkeleton(false); // Ensure skeleton loader is disabled on error
      });
  }, [formattedDate, selectedTimeZone, userMachineTime]); // Dependencies for triggering the effect


  function handleDateChange(newValue) {
    setIsWeekDayDisabled(false);
    setSelectDate(newValue);
  }
  const handleChange = (selectedTimeZone) => {
    setSelectedTimeZone(selectedTimeZone);
  };

  const handleConfirmationButton = () => {
    serviceInformation.bookingDate = formattedDate;
    serviceInformation.bookingTime = selectedTime;
    serviceInformation.timeZone = selectedTimeZone
      ? selectedTimeZone
      : { label: "(UTC +05:30) India (IST)", value: "Asia/Kolkata" };
    serviceInformation.userSelectedDate = selectDate.$d
      ? selectDate.$d
      : selectDate;
    serviceInformation.currency = advisorInfo.profileData.currency;
    serviceInformation.advisiorInformation = advisorInfo;

    if (selectedTime) {
      setModalData({ show: true, popupName: 'bookingDetails' });
      // navigate(`/${username}/${serviceInformation.platformServiceId}/payment`, {
      //   state: serviceInformation,
      // });
    } else {
      setError({
        selectedTime: "You need to select some time for your meeting.",
      });
    }
  };

  function goBackToPreviousPage() {
    navigate(-1);
  }

  function roundTime(time) {
    var timeParts = time.split(/[:\s]/);
    var hours = parseInt(timeParts[0], 10);
    var minutes = parseInt(timeParts[1], 10);
    var ampm = timeParts[2].toUpperCase();
    if (ampm === "PM" && hours < 12) {
      hours += 12;
    } else if (ampm === "AM" && hours === 12) {
      hours = 0;
    }
    var roundedMinutes = Math.ceil(minutes / 5) * 5;
    if (roundedMinutes === 60) {
      roundedMinutes = 0;
      hours++;
    }
    if (hours === 0) {
      hours = 12; // handle midnight as 12 AM
    } else if (hours > 12) {
      hours -= 12; // convert to 12-hour format
    }
    hours = hours < 10 ? "0" + hours : hours;
    var roundedHours =
      hours + ":" + (roundedMinutes < 10 ? "0" : "") + roundedMinutes;
    return roundedHours + " " + ampm;
  }
  //when user select time or date or timezone the time slot api will be called.

  function getDescription(description) {
    return DOMPurify.sanitize(description);
  }

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  function returnProfilePicString() {
    //if the image comes with the profileData then return the image.
    //also check wether it's a image name or the url the backend can contain anything .
    //if its a url then show the return the url else show the image name with.png

    //if it's not available then return the default pic.

    if (advisorInfo) {
      if (advisorInfo.profileData.profilePic) {
        const url = advisorInfo.profileData.profilePic;

        if (isValidUrl(url)) {
          return url;
        } else {
          try {
            const image = require(`../../Utility/images/${url}`);
            return image;
          } catch (error) {
            return defaultProfilePic;
          }
        }
      } else {
        return defaultProfilePic;
      }
    }
  }

  const StarRating = ({ totalStars = 5, rating = 0 }) => {
    const renderStars = () => {
      return Array.from({ length: totalStars }, (_, index) => {
        const starIndex = index + 1;
        const isFilled = starIndex <= rating;

        return (
          <svg
            key={index}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={isFilled ? "#FFD700" : "#D3D3D3"}
            style={{ width: "15px", height: '15px' }}
          >
            <path
              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
            />
          </svg>
        );
      });
    };

    return <div className="d-flex">{renderStars()}</div>;
  };

  const EllipsisWithTooltip = ({ text }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        className="ellipsis-text"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {text}
        {isHovered && <div className="custom-tooltip">{text}</div>}
      </div>
    );
  };

  const [index, setIndex] = useState(0);

  const nextReview = () => {
    if (index + 2 < serviceFeedback.length) {
      setIndex(index + 2);
    }
  };

  const prevReview = () => {
    if (index - 2 >= 0) {
      setIndex(index - 2);
    }
  };

  const { id } = useParams();
  const [modalData, setModalData] = useState({});
  const location = useLocation();
  // const isModalOpen = location.pathname.includes("/payment");

  const handleEditButton = () => {
    navigate(`/${username}/${id}`)
    closeModal(false);
  }

  const closeModal = () => {
    setModalData({ show: false });
  }

  const [bookingDetails, setBookingDetails] = useState({});

  const bookingConfirmDataFunc = (bookingConfirmData, response) => {
    setBookingDetails({ bookingConfirmData, ...response });
    setModalData({ show: true, popupName: 'bookingStatus' });
  }

  const closeBookingConfirmModal = () => {
    closeModal();
    navigate(`/${username}`, {
      state: serviceInformation,
    });
  }

  const bookingFailed = (response) => {
    setBookingDetails(response);
    setModalData({ show: true, popupName: 'bookingFailed' });
  }

  const modalDetails = useCallback(() => {
    switch (modalData?.popupName) {
      case 'bookingStatus':
        return (
          <Modal isOpen={modalData?.show} onClose={closeBookingConfirmModal}>
            <BookingConfirmation bookingConfData={bookingDetails} />
          </Modal>
        )
      case 'bookingDetails':
        return (
          <Modal isOpen={modalData?.show} onClose={closeModal}>
            <ServicePayment serviceInfo={serviceInformation} handleEditButton={handleEditButton} closeModal={closeModal} bookingConfirmDataFunc={bookingConfirmDataFunc} bookingFailed={bookingFailed} />
          </Modal>
        )
      case 'bookingFailed':
        return (
          <Modal isOpen={modalData?.show} onClose={closeModal}>
            <BookingFailed bookingFailData={bookingDetails} />
          </Modal>
        )
    }
  })

  return (
    <div className="video-call">
      <div className="container service-all-information service rounded border">
        <div className="d-flex flex-lg-row flex-column">
          <div className="col-lg-5 px-0 px-lg-3 service-details">
            {/* left-heading section  */}
            <div className="row common-nav px-0">
              <div
                className="d-flex flex-column align-items-start"
                style={{ gap: "1rem" }}
              >
                <div className="flex-item" onClick={goBackToPreviousPage}>
                  <span className="Go-back border rounded border-2 p-2">
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </div>
                <div className="flex-item">
                  <img
                    className="rounded-circle mt-4"
                    src={returnProfilePicString()}
                    alt="Avatar"
                    style={{ width: "120px", height: "120px" }}
                  />
                </div>
                <div className="flex-item">
                  <span className="service-user-name">
                    {advisorInfo.profileData.fullName}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex service-text">
              Service
            </div>

            {/* service detail section */}
            <div className="service-detail-section">
              <h2 className="service-title">
                {serviceInformation.title}
              </h2>
            </div>
            <div
              className="service-description"
              dangerouslySetInnerHTML={{
                __html: getDescription(serviceInformation.description),
              }}
            ></div>
            {/* price and minutes */}
            <div className="d-flex">
              <div className="service-duration text-center p-2 ">
                <div className="booking-minutes-footer">
                  <i className="fa-solid fa-stopwatch text-black pr-2"></i>
                  <span>{serviceInformation.callDuration} mins</span>
                </div>
              </div>
              <div className="service-charge text-center p-2 ml-2">
                <div className="booking-price-footer">
                  <i className="fa-solid fa-money-bill text-black pr-2"></i>
                  <span>
                    {getPriceUtility(
                      advisorInfo.profileData.currency,
                      serviceInformation.price
                    )}
                  </span>
                </div>
              </div>

            </div>


            {/* feedbacks */}

            {serviceFeedback.length === 0 ? (
              ""
            ) : (
              <div className="feedback-container">
                <div className="row">
                  <span className="feedback-title pb-3 pt-lg-0 pt-3">Reviews</span>
                </div>

                <div className="slider-section">
                  <div className="">
                    <div className="review-container">
                      {/* Navigation Arrows */}
                      <div className="controls">
                        <button
                          className={`arrow ${index === 0 ? "disabled" : ""}`}
                          onClick={prevReview}
                          disabled={index === 0}
                        >
                          <i className="fa-solid fa-angle-left"></i>
                        </button>
                        <button
                          className={`arrow ${index + 2 >= serviceFeedback.length ? "disabled" : ""}`}
                          onClick={nextReview}
                          disabled={index + 2 >= serviceFeedback.length}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </button>
                      </div>
                      <div className="review-wrapper">
                        {serviceFeedback.slice(index, index + 2).map((review, i) => (
                          <div key={i} className="user-review">
                            <div className="card-title">
                              <div className="feedback-title feedback-name-of-person">
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                  <div className="font-weight-bold fs-6 mr-3">
                                    {review.name}
                                  </div>
                                  <StarRating
                                    totalStars={5}
                                    rating={Math.round(review.rating)}
                                  />
                                </div>
                              </div>
                            </div>
                            <p className="card-text pt-2">
                              <EllipsisWithTooltip text={review.description} />
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-start">
                      <button className="see-all">See all reviews</button>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-7 px-lg-3 px-0 service-slots">
            {/* right heading section */}
            <div className="slots-header">
              <div className="heading">When should we meet ?</div>
            </div>

            {/* calendar api  */}
            <div className="calendar-container d-flex justify-content-center">
              <div className="row calender-inner">
                <div className="col-lg-12 px-0 px-lg-3">
                  <div className="main-calendar">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDatePicker
                        orientation="portrait"
                        openTo="day"
                        value={selectDate}
                        shouldDisableDate={isWeekend}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>

            {/* Time selection  */}

            <div className="time-selection-container mt-5 mb-3">
              <div className="col-lg-11 pl-lg-3 px-0">
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-12">
                      <label className="form-label-title-field mb-2">
                        Select Time
                      </label>
                    </div>
                  </div>

                  <div className="d-flex flex-row flex-wrap justify-content-start">
                    {skeleton ? (
                      <React.Fragment>
                        {Array.from({ length: 10 }).map((_, index) => (
                          <button
                            key={index}
                            className="btn btn-lg skeleton"
                            style={{
                              width: "95px",
                              height: "38px",
                              marginRight: "16px",
                              marginTop: "16px",
                            }}
                          ></button>
                        ))}
                      </React.Fragment>
                    ) : timeSlot.length === 0 ? (
                      <span className="text-danger">
                        All Slots are booked for today you can try booking
                        slots for tommorow
                      </span>
                    ) : (
                      <div className="timeslot-container d-flex flex-row flex-wrap justify-content-start w-100">
                        {" "}
                        {timeSlot.map((time, index) => {
                          return (
                            <div
                              className={`calls-button-${index} m-0 p-0`}
                              key={index}
                            >
                              <button
                                className={`${selectedTime === time
                                  ? "selected-time mt-3 mr-3"
                                  : "time-slot-div mt-3 mr-3"
                                  }`}
                                value={time}
                                onClick={() => {
                                  setSelectedTime(time);
                                }}
                              >
                                {time}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <span className="text-danger">
                    {error.selectedTime ? error.selectedTime : ""}
                  </span>
                </div>
              </div>
            </div>

            <div className="timezone">
              <Select
                value={selectedTimeZone ? selectedTimeZone : defaultOption}
                onChange={handleChange}
                options={timezones}
                isSearchable={true}
                className={"timezone-select"}
              // defaultValue={defaultOption}
              />
            </div>

            <div className="confirmation-button mt-4 mb-5 d-flex justify-content-center">
              <button
                className="btn btn-lg btn-primary confirm-btn"
                onClick={handleConfirmationButton}
              >
                Confirm Booking
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalData?.popupName && modalData?.show && modalDetails()}
    </div >
  );
};

export default ServiceInformation;
