import React, { useState } from "react";
import "./BookingConfirmation.css";
import bookingSuccess from "../../Utility/images/bookingSuccess.gif";
import { getPriceUtility } from "../../Utility/Price/getPriceUtility";
import { FaRegCopy } from "react-icons/fa";

const BookingConfirmation = ({ bookingConfData = {} }) => {
  // const location = useLocation();

  // const { state } = location;

  const calendarText =
    bookingConfData?.bookingConfirmData?.calendar === "text_query"
      ? "Query Response will be given in 2-3 days"
      : bookingConfData?.bookingConfirmData?.calendar;
  console.log(bookingConfData, "1111")

  const [copied, setCopied] = useState(false);
  const callLink = bookingConfData?.data?.callLinkForCx;

  const handleCopy = () => {
    navigator.clipboard.writeText(callLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Show "Copied!" for 2 seconds
  };

  return (
    <div className="booking-confirmation">
      <div className="container">
        <div className="booking-details container">
          <div className="details">
            <div className="booking-success-icon">
              <img
                src={bookingSuccess}
                width={300}
                height={300}
              />
            </div>

            <div className="booking-status text-center">
              <p className="h5 text-dark font-weight-bold">
                {bookingConfData?.bookingConfirmData?.calendar === "text_query"
                  ? "Payment Success!"
                  : "Payment Success!"}
              </p>
            </div>
            <div className="service-title text-center mb-4">
              {/* <p className="h5 text-dark">{bookingConfData?.bookingConfirmData?.title}</p> */}
              <p>Your Payment has been successfully done.</p>
            </div>
            <div className="d-flex justify-content-between">
              <div className="booking-title">
                Amount
              </div>
              <div className="booking-value">
                {getPriceUtility(bookingConfData?.bookingConfirmData?.currency, bookingConfData?.bookingConfirmData?.amount)}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="booking-title">
                Payment Status
              </div>
              <div className="payment-status">
                {bookingConfData?.data?.bookingConfirmation.toLowerCase()}
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <div className="booking-title">
                Booking Id
              </div>
              <div className="booking-value">
                {bookingConfData?.data?.bookingId}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="booking-title">
                Txn Id
              </div>
              <div className="booking-value">
                {bookingConfData?.data?.paymentId}
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="booking-title">
                Sender
              </div>
              <div className="booking-value text-capitalize">
                {bookingConfData?.data?.cxUsername?.toLowerCase()}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="booking-title">Meeting Link</div>
              {callLink && (
                <div className="d-flex align-items-center meeting-link">
                  <a
                    href={callLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="booking-value ellipsis-text text-primary text-truncate"
                    style={{ maxWidth: "calc(100% - 30px)" }} // Adjust width
                    title={callLink}
                  >
                    {callLink}
                  </a>
                  <FaRegCopy
                    className="ml-2 cursor-pointer text-muted"
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    onClick={handleCopy}
                    title="Copy to clipboard"
                  />
                  {copied && <span className="ml-2 text-success">Copied!</span>}
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div className="booking-title">
                Meeting Time
              </div>
              <div className="booking-value text-capitalize">
                {calendarText}
              </div>
            </div>
          </div>
          <div className="meeting-info">
            <p className="text-center">
              <small>
                Also Meeting details are sent to your Email and Mobile number
              </small>
            </p>
          </div>
        </div>
      </div>
    </div >
  );
};

export default BookingConfirmation;


