import React, { useRef } from "react";
import "./ServicePayment.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import url from "../../Utility/URL/url.js";
import constants from "../../Utility/Constants/constant.js";
import { toast } from "react-toastify";

import axios from "axios";
import useRazorpay from "react-razorpay";
import { getPriceUtility } from "../../Utility/Price/getPriceUtility";
import { useLoader } from "../../LoaderContext.js";

const ServicePayment = ({
  serviceInfo,
  handleEditButton = () => { },
  closeModal = () => { },
  bookingConfirmDataFunc = () => { },
  bookingFailed = () => { }
}) => {
  const Razorpay = useRazorpay();
  const location = useLocation();
  // const { state } = location;
  let paymentFailedStatus = false;
  const navigate = useNavigate();
  const usernameAdvisor = serviceInfo?.username;
  const fullName = serviceInfo?.advisiorInformation.profileData.fullName;

  const date = serviceInfo?.userSelectedDate;

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [callAbout, setCallAbout] = useState("");
  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  // Validate field onChange
  const validateField = (name, value) => {
    let error = "";

    if (name === "username" && !value.trim()) error = "Name must be entered";
    if (name === "email") {
      if (!value.trim()) {
        error = "Email must be entered";
      } else {
        const emailRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!emailRegex.test(value)) {
          error = "Invalid email format";
        }
      }
    }
    if (name === "phone") {
      if (isChecked || serviceInfo?.price > "0") {
        error = value ? "" : "Enter the phone number";
      }
    }

    setErrors((prev) => ({ ...prev, [name]: error }));
    checkFormValidity();
  };

  // Check form validity dynamically
  const checkFormValidity = () => {
    const isValid =
      username.trim() &&
      email.trim() &&
      (!isChecked || phone.trim()) &&
      (!serviceInfo?.price > "0" || phone.trim()) &&
      Object.values(errors).every((error) => error === "");

    setIsFormValid(isValid);
  };

  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  const { setIsLoading } = useLoader();
  const handleConfirmAndPay = () => {
    //validate the form first.

    //now check if the errors would have keys with non empty strings as values.
    setIsLoading(true);
    if (!isFormValid) return;
    handlePayment();
  };

  function getCallDetails() {
    return (
      findDayName(date) +
      ", " +
      date.getDate() +
      " | " +
      serviceInfo?.bookingTime +
      " - " +
      getMeetingEndtime(serviceInfo?.bookingTime, serviceInfo?.callDuration)
    );
  }
  const handlePayment = () => {
    if (serviceInfo?.price === "0" || serviceInfo?.price === 0) {
      axios
        .post(url.POST_SERVICE_BOOKING, {
          advisorUsername: serviceInfo?.advisiorInformation.profileData.username,
          platformServiceId: serviceInfo?.platformServiceId,
          bookingDate: serviceInfo?.bookingDate,
          bookingTime: serviceInfo?.bookingTime,
          cxEmail: email,
          aboutCall: callAbout,
          cxName: username,
          phoneNumber: phone,
          paymentStatus: constants.PAYMENT_STATUS_FREE,
          paymentId: null,
          title: serviceInfo?.title,
          callDuration: serviceInfo?.callDuration,
          timeZone: serviceInfo?.timeZone.value,
        })
        .then((response) => {
          toast.success("Booking successfully created", {
            position: toast.POSITION.TOP_CENTER,
          });
          //after toast go to the booking confirmation page.
          const callDetails = getCallDetails();
          const bookingConfirmationData = {
            title: serviceInfo?.title,
            calendar: callDetails,
            advisorInformation: serviceInfo?.advisiorInformation,
            currency: serviceInfo?.currency,
            amount: serviceInfo?.price
          };
          const { bookingId } = response.data;
          bookingConfirmDataFunc(bookingConfirmationData, response);

          // navigate(
          //   `/${serviceInfo?.platformServiceId}/bookingConfirmation/bookingId=${bookingId}`,
          //   {
          //     state: bookingConfirmationData,
          //   }
          // );
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 500) {
            {
              toast.error(constants.SOMETHING_WENT_WRONG, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
          setIsLoading(false);
          bookingFailed();
        });
    } else {
      //create order
      axios
        .post(url.POST_PAY_CREATE_ORDER, {
          amount: serviceInfo?.price,
          currency: serviceInfo?.currency,
          platformServiceId: serviceInfo?.platformServiceId,
          name: username,
          emailId: email,
          mobileNumber: phone,
          usernameAdvisor: usernameAdvisor,
        })
        .then((res) => {
          // after this i need to send the

          const usernameCustmoer = res.data.usernameCustmr;
          //usernameCustmr will come in response after creating the order
          // usernameCustmr, usernameAdvisor
          if (res.status === 200) {
            const options = {
              key: constants.RAZORPAY_key,
              amount: res.amount,
              currency: serviceInfo?.currency,
              name: constants.COMPANY_NAME,
              description: serviceInfo?.title,
              image: constants.COMPANY_LOGO,
              order_id: res.data.orderId,
              handler: function (response) {
                // This information is displayed after the successfull payment by the user.

                //this api is called after the payment is sucessfull.

                //pay response api called.

                axios
                  .post(url.POST_PAYMENT_PAY_RESPONSE, {
                    orderId: response.razorpay_order_id,
                    paymentId: response.razorpay_payment_id,
                    status: constants.PAYMENT_RESPONSE_STATUS_SUCCESS,
                    paymentStatus: constants.PAYMENT_STATUS_PAID,
                    usernameAdvisor: usernameAdvisor,
                    usernameCustmr: usernameCustmoer,
                  })
                  .then((response) => {
                    let paymentStatus = response.data.paymentStatus;
                    let paymentId = response.data.paymentId;

                    if (paymentStatus === "PAID") paymentFailedStatus = true;
                    setIsLoading(true);
                    axios
                      .post(url.POST_SERVICE_BOOKING, {
                        advisorUsername:
                          serviceInfo?.advisiorInformation.profileData.username,
                        platformServiceId: serviceInfo?.platformServiceId,
                        bookingDate: serviceInfo?.bookingDate,
                        bookingTime: serviceInfo?.bookingTime,
                        cxEmail: email,
                        aboutCall: callAbout,
                        cxName: username,
                        phoneNumber: phone,
                        paymentStatus: paymentStatus,
                        paymentId: paymentId,
                        title: serviceInfo?.title,
                        callDuration: serviceInfo?.callDuration,
                        timeZone: serviceInfo?.timeZone.value,
                        usernameAdvisor: usernameAdvisor,
                        usernameCustmr: usernameCustmoer,
                      })
                      .then((response) => {
                        setIsLoading(false);
                        toast.success("Booking successfully created", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        //after toast go to the booking confirmation page.

                        const callDetails = getCallDetails();
                        const bookingConfirmationData = {
                          title: serviceInfo?.title,
                          calendar: callDetails,
                          advisorInformation: serviceInfo?.advisiorInformation,
                          currency: serviceInfo?.currency,
                          amount: serviceInfo?.price
                        };
                        console.log({ callDetails, bookingConfirmationData })
                        const { bookingId } = response.data;
                        bookingConfirmDataFunc(bookingConfirmationData, response);
                        // navigate(
                        //   `/${serviceInfo?.platformServiceId}/bookingConfirmation/bookingId=${bookingId}`,
                        //   {
                        //     state: bookingConfirmationData,
                        //   }
                        // );
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        toast.warning(
                          "Service can't be created some techinical issue...",
                          {
                            position: toast.POSITION.TOP_RIGHT,
                          }
                        )
                        bookingFailed();
                      }
                      );
                  })
                  .catch((error) => {
                    toast.warning(error, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                    bookingFailed();
                  });
              },
              prefill: {
                name: username,
                email: email,
                contact: phone,
              },
              notes: {
                address: "",
              },
              theme: {
                color: "#3399cc",
              },
              modal: {
                ondismiss: async function () {
                  if (!paymentFailedStatus) {
                    const orderId = res.data.orderId;

                    try {
                      const res = await axios.post(
                        url.POST_PAYMENT_PAY_RESPONSE,
                        {
                          orderId: orderId,
                          paymentId: null,
                          status: constants.CANCELED_BY_USER,
                          paymentStatus: constants.CANCELED_BY_USER,
                        }
                      );

                      if (res.status === 200) {
                        toast.warn("Your booking is not created....");
                        window.location.reload();
                        closeModal();
                      }
                    } catch (error) {
                      console.log("I am in error");
                      toast.error("Server error", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                  }
                },
              },
            };

            const rzp1 = new Razorpay(options);
            rzp1.open();

            rzp1.on("payment.failed", function (response) {
              paymentFailedStatus = true;
              toast.error("Payment Failed", {
                position: toast.POSITION.TOP_RIGHT,
              });
              const orderId = response.error.metadata.order_id;
              const paymentId = response.error.metadata.payment_id;

              axios
                .post(url.POST_PAYMENT_PAY_RESPONSE, {
                  orderId: orderId,
                  paymentId: paymentId,
                  status: constants.PAYMENT_RESPONSE_STATUS_FAIL,
                  paymentStatus: constants.PAYMENT_STATUS_FAILED,
                })
                .then((response) => {
                  setIsLoading(false);
                  bookingFailed(response)
                  console.log("response after fail api ", response);
                })
                .catch((error) => {
                  /// when it's network issue or something then the api should call
                  // console.log(error);
                  setIsLoading(false);

                  axios
                    .post(url.POST_PAYMENT_PAY_RESPONSE, {
                      orderId: orderId,
                      paymentId: paymentId,
                      status: constants.PAYMENT_RESPONSE_STATUS_ERROR,
                      paymentStatus: constants.PAYMENT_STATUS_FAILED,
                    })
                    .then((res) => {
                      setIsLoading(false);
                      console.log(res)
                    });
                });
            });
            closeModal();
          }
        })
        .catch((error) => {
          toast.warning(error, { position: toast.POSITION.TOP_RIGHT })
          setIsLoading(false);
        });
    }
  };



  const findMonth = (date) => {
    const options = { month: "long" };
    return date.toLocaleDateString("en-US", options);
  };

  const findDayName = (date) => {
    const options = { weekday: "long" };

    return date.toLocaleDateString("en-US", options);
  };

  const getMeetingEndtime = (startTime, minutes) => {
    const moment = require("moment");

    const momentObj = moment(startTime, "hh:mm a");
    momentObj.add(minutes, "minutes");
    const updatedTime = momentObj.format("hh:mm a");
    return updatedTime;
  };

  return (
    <div className="container bg-light rounded">
      <div className="row">
        <div className="mx-auto col-12">
          <div className="sp-details">
            {/* <div className="row header">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="d-flex flex-row" style={{ gap: "1rem" }}>
                  <div
                    className="service-payments-heading-left-arrow mx-2 Go-back"
                    onClick={goBack}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </div>

                  <div className="service-payments-heading-avatar">
                    <div className="avatar">avatar</div>
                  </div>
                  <div className="service-payments-heading-username">
                    <div className="username">{fullName}</div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row pt-3">
              <div className="col-6">
                <h3 className="service-title">{serviceInfo?.title}</h3>
                <div className="service-description pt-2 pb-3">
                  <span className="description-text">Video Meeting</span>{" "}
                  <span className="service-minutes">
                    {serviceInfo?.callDuration} Mins
                  </span>
                </div>
              </div>

              <div
                className="col-6"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                <span className="price-text">
                  {getPriceUtility(serviceInfo?.currency, serviceInfo?.price)}
                </span>
              </div>
            </div>

            {/* //meeting information here */}
            <div className="row">
              <div className="col-12">
                <div className="alert alert-success" role="alert">
                  <div className="alert-content">
                    <div className="row align-items-center">
                      <div className="col-10 col-lg-8 alert-message">
                        <span className="alert-calendar mx-2">
                          <i className="fa-solid fa-calendar"></i>
                        </span>
                        <span className="alert-calendar-information">
                          {findDayName(date)} {date.getDate()}{" "}
                          {findMonth(date)} | {serviceInfo?.bookingTime} -{" "}
                          {getMeetingEndtime(
                            serviceInfo?.bookingTime,
                            serviceInfo?.callDuration
                          )}
                          {"  "}
                          {serviceInfo?.timeZone.label}
                        </span>
                      </div>
                      <div className="col-2 col-lg-4 alert-avatar text-end">
                        <span className="edit-link">
                          <i className="fa-solid fa-pen" onClick={handleEditButton}></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row service-form">
              <div className="col-12">
                <form className="form-for-service">
                  {/* Username Field */}
                  <div className="form-group mt-2 mb-1">
                    <label className="form-label">
                      <sup>
                        <i className="fa-solid fa-asterisk"></i>
                      </sup>{" "}
                      Your Name
                    </label>
                    <input
                      type="text"
                      className="form-control username"
                      placeholder="Enter your name"
                      name="username"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        validateField("username", e.target.value);
                      }}
                      required
                    />
                    {errors.username && <span className="text-danger">{errors.username}</span>}
                  </div>

                  {/* Email Field */}
                  <div className="form-group mt-3 mb-1">
                    <label className="form-label">
                      <sup>
                        <i className="fa-solid fa-asterisk"></i>
                      </sup>{" "}
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control email"
                      placeholder="Enter your email"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        validateField("email", e.target.value);
                      }}
                      required
                    />
                    {errors.email && <span className="text-danger">{errors.email}</span>}
                  </div>

                  {/* Call About Field */}
                  <div className="form-group mt-3 mb-1">
                    <label className="form-label">What is the call about</label>
                    <input
                      type="text"
                      className="form-control call-about"
                      placeholder="I would like to discuss"
                      name="callAbout"
                      value={callAbout}
                      onChange={(e) => setCallAbout(e.target.value)}
                      required
                    />
                  </div>

                  {/* Phone Number Field */}
                  <div className="form-group mt-3 mb-1">
                    <label className="form-label">Phone number</label>
                    <div className="country-flag">
                      <PhoneInput
                        country={"in"}
                        value={phone}
                        onChange={(phone) => {
                          setPhone(phone);
                          validateField("phone", phone);
                        }}
                      />
                    </div>
                    {errors.phone && <span className="text-danger">{errors.phone}</span>}
                  </div>
                  {/* //If this checkbox is tick then we need to send the details to the user provided mobile number. */}
                  {/* <div className="form-check mt-3 mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label">
                      Recieve Booking Details on Phone
                    </label>
                  </div> */}
                </form>
              </div>
            </div>

            <div className="row">
              <div
                className="d-flex justify-content-center mt-4"
                style={{ paddingLeft: "7px", paddingRight: "7px" }}
              >
                <button
                  className="payNowButton"
                  onClick={handleConfirmAndPay}
                  disabled={!isFormValid}
                >
                  {serviceInfo?.price === "0" || serviceInfo?.price === 0
                    ? "Confirm"
                    : "Pay Now"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePayment;
