import React, { useState } from "react";
import "./BookingFailed.css";
import bookingFailed from "../../Utility/images/bookingFailed.gif";
import { getPriceUtility } from "../../Utility/Price/getPriceUtility";

const BookingFailed = ({ bookingFailData }) => {
    // const location = useLocation();

    // const { state } = location;

    return (
        <div className="booking-confirmation">
            <div className="container">
                <div className="booking-details container">
                    <div className="details">
                        <div className="booking-success-icon">
                            <img
                                src={bookingFailed}
                                width={300}
                                height={300}
                            />
                        </div>

                        <div className="booking-status text-center">
                            <p className="h5 text-dark font-weight-bold fs-2">
                                "Payment Failed!"
                            </p>
                        </div>
                        <div className="service-title text-center mb-4 fs-6">
                            {/* <p className="h5 text-dark">{bookingConfData?.title}</p> */}
                            <i><p>Due to some technical issue Payment is failed. Please Try again after sometime.</p></i>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="booking-title">
                                Amount
                            </div>
                            <div className="booking-value">
                                {getPriceUtility(bookingFailData?.data?.currency, bookingFailData?.data?.amount)}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="booking-title">
                                Payment Status
                            </div>
                            <div className="payment-status-failed">
                                {bookingFailData?.data?.paymentStatus.toLowerCase()}
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <div className="booking-title">
                                Ref Id
                            </div>
                            <div className="booking-value">
                                {bookingFailData?.data?.receiptId}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="booking-title">
                                Txn Id
                            </div>
                            <div className="booking-value">
                                {bookingFailData?.data?.paymentId}
                            </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <div className="booking-title">
                                Sender
                            </div>
                            <div className="booking-value text-capitalize">
                                {bookingFailData?.data?.usernameCustmr?.toLowerCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default BookingFailed;


